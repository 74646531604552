import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import { AspectRatioWrapper, ResponsiveImage } from '../../../components';
import defaultConfig from '../../../config/configDefault';
import css from './SearchMapInfoCard.module.css';

const typeLabel = (type) => {
  return defaultConfig?.typeOfScanerOptions?.filter(item => item.key === type)?.[0]?.label
}


// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing, config } = props;

  const { title, price, publicData } = listing.attributes;
  const { typeOfScaner = null, location = null } = publicData;
  const typeOfScanerValues = !!typeOfScaner?.length ? typeOfScaner?.map(item => typeLabel(item)) : null

  const formattedPrice =
    price && price.currency === config.currency ? formatMoney(intl, price) : price.currency;
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbVariants = thumbnailVariants || imageVariants;

  const imageSizesMaybe = { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="27"
            fill="none"
            viewBox="0 0 32 27"
          >
            <path fill="#fff" d="M32 4a4 4 0 00-4-4H0v27h28a4 4 0 004-4V4z"></path>
            <path
              fill="#323232"
              d="M13.219 13.333h8.115v1.334h-8.115l3.576 3.576-.943.942L10.667 14l5.185-5.185.943.942-3.576 3.576z"
            ></path>
          </svg>
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="27"
            fill="none"
            viewBox="0 0 32 27"
          >
            <path fill="#fff" d="M0 4a4 4 0 014-4h28v27H4a4 4 0 01-4-4V4z"></path>
            <path
              fill="#323232"
              d="M18.781 14.667h-8.115v-1.334h8.115l-3.576-3.576.943-.942L21.333 14l-5.185 5.185-.943-.942 3.576-3.576z"
            ></path>
          </svg>
        </div>
      </button>
    );
  };

  const renderItem = item => {
    return (
      <a
        alt={title}
        className={css.link}
        href={urlToListing}
        onClick={e => {
          e.preventDefault();
          // Use clickHandler from props to call internal router
          clickHandler(listing);
        }}
      >
        <AspectRatioWrapper
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          className={css.itemWrapper}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
              {...imageSizesMaybe}
            />
          </div>
        </AspectRatioWrapper>
      </a>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  return (
    <div className={classes}>
      <ReactImageGallery
        additionalClass={classNames(css.productGallery, { [css.productGallerySingle]: items?.length === 1 })}
        items={items}
        renderItem={renderItem}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showPlayButton={false}
        disableThumbnailScroll={true}
        showThumbnails={false}
        showFullscreenButton={false}
        showBullets={true}
      />

      <a
        alt={title}
        className={css.link}
        href={urlToListing}
        onClick={e => {
          e.preventDefault();
          // Use clickHandler from props to call internal router
          clickHandler(listing);
        }}
      >
        <div
          className={classNames(css.card, css.borderRadiusInheritTop, {
            [css.borderRadiusInheritBottom]: !isInCarousel,
          })}
        >
          {/* <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          <ResponsiveImage
            rootClassName={classNames(css.rootForImage, css.borderRadiusInheritTop)}
            alt={title}
            noImageMessage={intl.formatMessage({ id: 'SearchMapInfoCard.noImage' })}
            image={firstImage}
            variants={variants}
            sizes="250px"
          />
        </AspectRatioWrapper> */}

          <div className={classNames(css.info, { [css.borderRadiusInheritBottom]: !isInCarousel })}>
            <div className={css.name}>{title}</div>
            {!!typeOfScanerValues?.length && (
              <div className={css.infoItem}>
                {typeOfScanerValues?.join(", ")}
              </div>
            )}
            {!!location?.address && (
              <div className={css.infoItem}>
                {location.address}
              </div>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;
  const currentListing = ensureListing(listings[currentListingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className);
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <div className={css.caretShadow} />
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>
            {`${currentListingIndex + 1}/${listings.length}`}
          </div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
